








import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  defineComponent,
  onMounted,
  PropType
} from '@vue/composition-api';
import SingleOperationalForm from './SingleOperationalForm.vue';

export default defineComponent({
  components: { KText, SingleOperationalForm, KCard },
  name: 'OperationalsForm',
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    }
  },
  setup(props) {
    const defaultOperationalData = {
      subject: '',
      needsPerMonth: null,
      pricePerUnit: null,
      totalNeedsPerMonth: null,
      totalNeedsPerYear: null
    };

    onMounted(() => {
      const propsData: any = props.data;
      const hasOperationals = propsData.operationalCosts?.length;

      if (hasOperationals) return;

      propsData.operationalCosts = [{ ...defaultOperationalData }];
    });

    return {
      defaultOperationalData
    };
  }
});
