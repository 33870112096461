





































import { defineComponent, Ref, ref } from '@vue/composition-api'
import KBaseInput from './KBaseInput.vue'
import useInputStyles from "@/@core/styles/inputStyles"
import useModelValue from '@/@core/helpers/modelValue'
import KText from '../typography/KText.vue'

export default defineComponent({
    components: { KBaseInput, KText },
    name: 'KArrayInput',
    props: ['defaultValue'],
    setup(props, { attrs, emit }) {
        const {label, ...filteredAttrs} = attrs
        const modelValue = useModelValue({ attrs, emit })

        const reRenderCount: Ref<number> = ref(0)

        const addValue = () => modelValue.value.push(props.defaultValue)
        const deleteValue = (index: number) => {
            modelValue.value.splice(index, 1)
            reRenderCount.value ++
        }

        return {
            useInputStyles,
            filteredAttrs,
            modelValue,
            addValue,
            deleteValue,
            reRenderCount
        }
    }
})
