





































































import {
  computed,
  defineComponent,
  Ref,
  ref,
  PropType,
  watch
} from '@vue/composition-api';
import KBaseInput from './KBaseInput.vue';
import useInputStyles from '@/@core/styles/inputStyles';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import KDialog from '../dialogs/KDialog.vue';
import KText from '../typography/KText.vue';
import useModelValue from '@/@core/helpers/modelValue';
import useInputRules from '@/@core/helpers/inputRules';
import KTextInput from './KTextInput.vue';
import { useDebounce } from '@vueuse/core';

type Item = {
  value: string;
  icon: string;
  text: string;
  [v: string]: string;
};

export default defineComponent({
  components: { KBaseInput, KDialog, KText, KTextInput },
  name: 'KComboboxInput',
  props: {
    items: {
      type: Array as PropType<Item[]>,
      default: () => []
    },
    'item-value': {
      type: String,
      default: 'value'
    },
    'item-text': {
      type: String,
      default: 'text'
    },
    'is-server-side': {
      type: Boolean,
      default: false
    },
    'disable-mandatory': {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updated'],
  setup(props, { attrs, emit }) {
    const { label, rules, ...filteredAttrs } = attrs;
    const showDialog = ref(false);
    const modelValue = useModelValue({ emit, attrs });
    const searchInput = ref('');
    const debouncedSearchInput = useDebounce(searchInput, 500);

    watch(debouncedSearchInput, async (search) =>
      emit('updated', search)
    );

    const filteredItems = computed(() => {
      if (props['is-server-side']) emit('updated', debouncedSearchInput.value);

      if (!searchInput) return props.items;

      return props.items.filter((item) =>
        JSON.stringify(item)
          .toLowerCase()
          .includes(String(searchInput.value).toLowerCase())
      );
    });

    const formattedRules: Ref<any[]> = computed(() =>
      rules ? useInputRules(rules as any[], attrs.value) : []
    );

    const onClick = () => {
      if (attrs.readonly || attrs.disabled) return;

      showDialog.value = true;
    };

    watch(showDialog, (val) => {
      if (!val) searchInput.value = '';
    });

    return {
      useInputStyles,
      filteredAttrs,
      isMobile,
      showDialog,
      modelValue,
      formattedRules,
      searchInput,
      filteredItems,
      onClick
    };
  }
});
